/**
 * @description 绑定事件 on(element, event, handler)
 */
export const on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})();


/**
  不同状态显示不同的颜色和字体
*/
export const changeColor = (data) => {
  let color;
  switch(data){
    case 0:
      color = 'red';
      break;
    case 1:
      color = 'green';
      break;
    case 2:
      color = 'blue';
      break;
  };
  return color;
};
/**
  获取状态
 */
export const getStatuTxt = (data) => {
  let statuTxt;
  switch(data){
    case 0:
      statuTxt = '未处理';
      break;
    case 1:
      statuTxt = '已处理';
      break;
    case 2:
      statuTxt = '误报';
      break;
  };
  return statuTxt;
};
/*
  转换告警详情数据
*/
export const getNoticeUnames = (item) => {  
  item.point = item.point ? JSON.parse(item.point) : [];
  item.area = item.area ? JSON.parse(item.area) : [];
  item.area_new = item.area_new ? JSON.parse(item.area_new) : '';
  if(typeof item.notice_unames == 'string'){
    item.notice_unames = item.notice_unames.split(',');
  }
  item.img_list.push(item.alarm_img);
  return item;
}