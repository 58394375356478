<template>
    <div class="page">
        <Aside></Aside>
        <div class="home-right">
            <div class="content-top">
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-1.png'/>
                        <div class="info-detail cursor">
                            <div class="title">告警总数</div>
                            <div class="num red">{{inforCardData ? inforCardData.all.alarm_total : '--'}}</div>
                        </div>
                    </div>
                    <div class="data-bottom">
                        <div class="title">新增告警:</div>
                        <div class="num red">{{inforCardData ? inforCardData.today.alarm_total : '--'}}</div>
                    </div>
                </div>
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-2.png'/>
                        <div class="info-detail cursor">
                            <div class="title">处理数量</div>
                            <div class="num blue">{{inforCardData ? inforCardData.all.alarm_deal_total : '--'}}</div>
                        </div>
                    </div>
                    <div class="data-bottom">
                        <div class="title">新增处理:</div>
                        <div class="num blue">{{inforCardData ? inforCardData.today.alarm_deal_total : '--'}}</div>
                    </div>
                </div>
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-3.png'/>
                        <div class="info-detail cursor">
                            <div class="title">未处理数量</div>
                            <div class="num green">{{inforCardData ? inforCardData.all.alarm_undeal_total : '--'}}</div>
                        </div>
                    </div>
                    <div class="data-bottom">
                        <div class="title">新增未处理:</div>
                        <div class="num green">{{inforCardData ? inforCardData.today.alarm_undeal_total : '--'}}</div>
                    </div>
                </div>
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-4.png'/>
                        <div class="info-detail cursor">
                            <div class="title">推送数量</div>
                            <div class="num yellow">{{inforCardData ? inforCardData.all.push_total : '--'}}</div>
                        </div>
                    </div>
                    <div class="data-bottom">
                        <div class="title">新增推送:</div>
                        <div class="num yellow">{{inforCardData ? inforCardData.today.push_total : '--'}}</div>
                    </div>
                </div>
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-5.png'/>
                        <div class="info-detail cursor">
                            <div class="title">报表数量</div>
                            <div class="num lightred">{{inforCardData ? inforCardData.all.template_total : '--'}}</div>
                        </div>
                    </div>
                    <div class="data-bottom">
                        <div class="title">新增报表:</div>
                        <div class="num lightred">{{inforCardData ? inforCardData.today.template_total : '--'}}</div>
                    </div>
                </div>
                <div class="data-box">
                    <div class="data-top">
                        <img src='./images/download-6.png'/>
                        <div class="info-detail cursor">
                            <div class="title">告警规则数量</div>
                            <div class="num green">{{inforCardData ? inforCardData.all.alarm_rule_total : '--'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-bottom">
                <div class="content-left">
                    <div class="content-title">最新告警</div>
                    <div class="home-b-l-content">
                        <div class="alarm-header">
                            <div class="header-title title">告警名称</div>
                            <div class="header-time time">告警时间</div>
                            <div class="header-statu statu">状态</div>
                        </div>
                        <!--  @click="goDetail(item)" -->
                        <div class="alarm-info" v-for="item in alarmList">
                            <div class="alarm-info-title title overflow">{{item.alarm_type_name}}</div>
                            <div class="alarm-info-time time">{{item.alarm_time}}</div>
                            <div class="alarm-info-statu statu" :class="getColor(item.status)">{{item.status | getStatus}}</div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="contentR-top">
                        <div class="data-box" @click="goModelYun('#todo')">
                            <div class="title">
                                <span>待办</span>
                                <div class="data-change">
                                    <img src="./images/up.png" />
                                    <span class="txt">+0</span>
                                </div>
                            </div>
                            <div class="num">{{dashData ? dashData.dash.count : '--'}}</div>
                        </div>
                        <div class="data-box" @click="goModelYun('#cc')">
                            <div class="title greentitle">
                                <span>抄送</span>
                                <div class="data-change">
                                    <img src="./images/up.png" />
                                    <span class="txt">+0</span>
                                </div>
                            </div>
                            <div class="num">{{dashData ? dashData.flow_todo.allcount : '--'}}</div>
                        </div>
                        <div class="data-box" @click="goModelYun('#launch')">
                            <div class="title bluetitle">
                                <span>我发起的</span>
                                <div class="data-change">
                                    <img src="./images/up.png" />
                                    <span class="txt">+0</span>
                                </div>
                            </div>
                            <div class="num">{{dashData ? dashData.flow_start.count : '--'}}</div>
                        </div>
                    </div>
                    <div class="contentR-bottom">
                        <div class="title">报警趋势</div>
                        <div ref="alarmLine" style="width:100%;flex:1;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Aside from "./components/aside";
import {getIndexData,getLatestNewData,getWeekAlarmData,getDash,getSiteUrl} from "@/api/data.js";
import echarts from 'echarts';
import { on, off } from '@/libs/tools'

export default {
    components:{Aside},
    data(){
        return {
            alarmList:[],
            columns:[
                {
                    title: '告警名称',
                    dataIndex: 'alarm_type_name',
                    align:'left',
                },
                {
                    title: '告警时间',
                    dataIndex: 'alarm_time',
                    align:'left',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    align:'left',
                    scopedSlots: { customRender: 'status' },
                },
            ],
            inforCardData:'',
            isReloadData:true,
            pageSize:8,
            loading:false,
            dashData:'',
            alarmLine:'',
            timer:'',
            teamInfo:'',
        }
    },
    filters:{
        getStatus(val){
            let statuTxt;
            switch(val){
                case 0:
                    statuTxt = '未处理';
                    break;
                case 1:
                    statuTxt = '已处理';
                    break;
                case 2:
                    statuTxt = '误报';
                    break;
            };
            return statuTxt;
        }
    },
    mounted(){
        this.teamInfo = JSON.parse(localStorage.getItem('teamInfo'));
        this.init();
        this.timer = setInterval(() => {
            this.init();
        }, 60000);
    },
    methods:{
        init(){
            this.getIndexData();
            this.getLatestNewData();
            this.getDash();
            this.getAlarmData();
        },
        getIndexData(){
            getIndexData().then(res => {
                if(res.code === 1){
                    this.inforCardData = res.data;
                }
            })
        },
        getLatestNewData(){
            this.loading = true;
            let params = {
                page:1,
                pageSize:this.pageSize,
            }
            getLatestNewData(params).then(data => {
                this.loading = false;
                if(data.code === 1){
                    this.alarmList = data.data.map(item => {
                        item.key = item.alarm_id;
                        return item;
                    });
                }
            })
        },
        getColor(data){
            let color;
            switch(data){
                case 0:
                    color = 'red';
                    break;
                case 1:
                    color = 'green';
                    break;
                case 2:
                    color = 'blue';
                    break;
            };
            return color;
        },
        getDash(){
            getDash({
                team_id:this.teamInfo.team_id,
                matching_userid:this.teamInfo.matching_userid,
            }).then(res => {
                if(res.code === 1){
                    this.dashData = res.data;
                    this.dashData.dash.count = this.dashData.dash.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                    this.dashData.flow_todo.allcount = this.dashData.flow_todo.allcount.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                    this.dashData.flow_start.count = this.dashData.flow_start.count.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                }
            })
        },
        goModelYun(item){
            getSiteUrl({
                team_id:this.teamInfo.team_id,
                matching_userid:this.teamInfo.matching_userid,
                url:'/dashboard'+item
            }).then(res => {
                if(res.code === 1){
                    window.open(`${res.data.path}`, '_blank');
                }
            })
        },
        getAlarmData(){
            getWeekAlarmData().then(res => {
                if(res.code === 1){
                    this.setAlarmLine(res.data);
                }
            })
        },
        setAlarmLine(data){
            this.alarmLine = echarts.init(this.$refs.alarmLine);
            this.alarmLine.clear();
            let xAxisData = [];
            let seriesData = [];
            data.forEach(item => {
                xAxisData.push(item.name);
                seriesData.push(item.value);
            });
            let option = {
                grid:{
                    top:'6%',
                    bottom:'10%',
                    left:'5%',
                    right:'5%',
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    splitLine:{show: false},
                    axisLine: {
                        lineStyle:{
                            color:'#E5E5E5',
                        }
                    },
                    axisLabel: {
                        color: '#9C9C9C',
                        fontFamily:'Montserrat',
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine:{show: false},
                    minInterval: 1,
                    axisLine: {
                        show: true,
                        lineStyle:{
                            color:'#E5E5E5',
                        },
                    },
                    axisLabel: {
                        color: '#9C9C9C',
                        fontFamily:'Montserrat',
                        formatter(params){
                            let value;
                            if(params <= 999){
                                value = params;
                            }else if(params > 999){
                                value = (params/1000).toFixed(1) + 'k';
                            }else if(params > 9999){
                                value = (params/10000).toFixed(1) + 'w';
                            }
                            return value
                        },
                    },
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: seriesData,
                    type: 'line',
                    smooth: true,
                    showSymbol: false, 
                    itemStyle : {  
                        normal : {  
                            color: '#007AFF',
                        }  
                    }, 
                    areaStyle:{
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(0, 122, 255, 0.33)'
                        }, {
                            offset: 1,
                            color: 'rgba(255, 255, 255, 0)'
                        }])
                    },
                    lineStyle: {
                        width: 2,
                        color:'#007AFF',
                    },
                }]
            }
            this.alarmLine.setOption(option);
            on(window, 'resize', this.resize)
        },
        resize(){
            this.alarmLine.resize();
        }
    },
    beforeDestroy () {
        off(window, 'resize', this.resize)
    },
    destroyed(){
        clearInterval(this.timer);
    }
}
</script>

<style lang="scss" scoped>
$shadow:0px 16px 30px rgba(0, 0, 0, .03), 0px 2px 6px rgba(0, 0, 0, .03), 0px 0px 1px rgba(0, 0, 0, .03);
.home-right{
    padding:30px;
    flex:1;
    display:flex;
    flex-direction: column;
    .content-top{
        display:flex;
        justify-content: space-between;
        height:20.9%;
        text-align: center;
        .data-box{
            box-shadow: $shadow;
            border-radius: 20px;
            width:14.51%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .data-top{
                display:flex;
                align-items: center;
                justify-content: center;
                .info-detail{
                    margin-left:12%;
                    .num{
                        margin-top:11px;
                    }
                }
            }
            .data-bottom{
                display: flex;
                justify-content: center;
                align-items: center;
                .num{
                    margin-left:13px;
                }
            }
            .title{
                font-size: 14px;
                line-height: 24px;
                color:#696969;
            }
            .num{
                font-size: 20px;
                line-height: 24px;
            }
            .red{
                color:#F6552C;
            }
            .blue{
                color:#1890FF;
            }
            .green{
                color:#6CD89F;
            }
            .yellow{
                color:#FFCF5D;
            }
            .lightred{
                color:#E78352;
            }
        }
    }
    .content-bottom{
        flex:1;
        display:flex;
        padding-top:30px;
        .content-left{
            width:34.68%;
            height:100%;
            padding:30px 18px 0;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            box-shadow: $shadow;
            border-radius: 20px;
            .content-title{
                font-size: 18px;
                line-height: 27px;
                color: #404040;
                margin:0 12px 9%;
            }
            .home-b-l-content{
                background: #fff;
                flex:1;
                position: relative;
                overflow:hidden; 
                padding-bottom:12px;
                display:flex;
                flex-direction: column;
                .title{
                    width:33.4%;
                }
                .time{
                    min-width:140px;
                    width:33.3%;
                    // text-align: center;
                }
                .statu{
                    min-width:42px;
                    width:11.1%;
                }
                .alarm-header{
                    display:flex;
                    justify-content: space-between;
                    font-size: 14px;
                    line-height: 21px;
                    color: #AEAEAE;
                    text-align: left;
                    padding:0 12px;
                }
                .alarm-info{
                    height:16.7%;
                    padding:0 12px;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
                    color:#404040;
                    .alarm-info-title{
                        font-size:16px;
                    }
                    .alarm-info-statu{
                        font-size: 14px;
                        min-width:50px;
                    }
                    .red{
                        color:#F6552C;
                    }
                    .green{
                        color:#42BA7B;
                    }
                    .blue{
                        color:#1890FF;
                    }
                }
            }
        }
        .content-right{
            margin-left:30px;
            flex:1;
            box-sizing: border-box;
            .contentR-top{
                display:flex;
                width:100%;
                height: 20.6%;
                justify-content: space-between;
                .data-box{
                    width: 28.8%;
                    box-shadow: $shadow;
                    border-radius: 20px;
                    box-sizing: border-box;
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left:30px;
                    cursor: pointer;
                    .title{
                        font-size: 18px;
                        line-height: 27px;
                        color:#EA3D2F;
                        display:flex;
                        align-items: center;
                        .data-change{
                            margin-left:20px;
                            width: 53px;
                            height: 20px;
                            background: #C3EE83;
                            border-radius: 5px;
                            display:flex;
                            align-items: center;
                            padding:0 4px;
                            box-sizing: border-box;
                            .txt{
                                line-height:20px;
                                margin-left:6px;
                                font-size: 14px;
                                color: #489019;
                            }
                        }
                    }
                    .greentitle{
                        color:#2FA84F;
                    }
                    .bluetitle{
                        color:#367BF5;
                    }
                    .num{
                        font-family: SF Pro;
                        margin-top: 8.2%;
                        font-size: 28px;
                        line-height: 33px;
                        color: #2C2C2C;
                        font-weight: bold;
                        word-wrap: break-word;
                    }
                }
            }
            .contentR-bottom{
                margin-top:30px;
                height:calc(79.4% - 30px);
                box-shadow: $shadow;
                border-radius: 20px;
                padding:30px 0 0 30px;
                box-sizing: border-box;
                display:flex;
                flex-direction: column;
                .title{
                    font-size: 18px;
                    color:#000;
                    font-family:Source Han Sans CN;
                    line-height:27px;
                }
            }
        }
    }
}
</style>